import axios from 'axios'

import { API_LOCATION } from "@/api"


export default {
    getMe() {
        return axios.get(`${API_LOCATION}/me`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getShopCategories(data) {
        return axios.get(`${API_LOCATION}/categories`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data
        })
    },
}