import axios from 'axios'

import { API_LOCATION } from "@/api"
const url = API_LOCATION+"/vizits"

 
export default {
    getModels(data) {
        return axios.get(`${url}`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data 
        })
    },
    getPeopleChart(shop_id, data) {
        return axios.get(`${url}/${shop_id}/peoples-chart`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data
        })
    },
    getModel(id) {
        return axios.get(`${url}/${id}`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    createModel(data) {
        return axios.post(`${url}`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateModel(data) {
        return axios.put(`${url}/${data.id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    deleteItem(id) {

        return axios.delete(`${url}/${id}`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
   
}