
import { createRouter, createWebHistory } from 'vue-router';
import PageShops from '@/pages/Shops/PageShops';
import AddShop from '@/pages/Shops/AddShop';


import PageLogin from '@/pages/PageLogin';

import PageVizits from '@/pages/Vizits/PageVizits';
import AddVizit from '@/pages/Vizits/AddVizit'

const routes = [
    {
        path: '/login',
        component: PageLogin
    },
    {
        name: "Shops",
        path: '/shops',
        component: PageShops,
    },
    {
        name: "AddShop",
        path: '/add-shop',
        component: AddShop,
    },
    {
        name: "Vizits",
        path: '/vizits',
        component: PageVizits,
    },
    {
        name: "addVizit",
        path: '/add-vizit/:id',
        component: AddVizit,
        props: true
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router; 