import AppButtonMenu from '@/components/UI/AppButtonMenu';
import AppButtonIcon from '@/components/UI/AppButtonIcon';
import AppSearch from '@/components/UI/AppSearch';
import AppIconAttention from '@/components/UI/AppIconAttention';
import AppSelect from '@/components/UI/AppSelect';

import AppInput from '@/components/UI/AppInput';
import AppButton from '@/components/UI/AppButton';
import AppNavigationBottom from '@/components/UI/AppNavigationBottom';
import AppSelect2 from '@/components/UI/AppSelect2';
import AppSelectorShow from '@/components/UI/AppSelectorShow';
import AppMessageNotice from '@/components/UI/AppMessageNotice';
import AppHeaderMenu from '@/components/UI/AppHeaderMenu';
import AppSelectElement from '@/components/UI/AppSelectElement';







export default [
   AppHeaderMenu,
   AppSelectElement,
   AppMessageNotice,
   AppButtonMenu,
   AppButtonIcon,
   AppSearch,
   AppIconAttention,
   AppInput,
   AppButton,
   AppSelect,
   AppNavigationBottom,
   AppSelect2,
   AppSelectorShow
];