<template>
  <div class="selector-container">
    <div class="selector-button item-option"
         @click="openMenu"
    >
      {{ currentSelect }}
      <span>
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.01807 6.74878L6.0825 6.81322L11.3584 1.53734L10.1156 0.294592L5.63125 4.77897L1.21719 0.364906L0.0447706 1.53732L5.63715 7.1297L6.01807 6.74878Z"
                fill="black"/>
        </svg>
      </span>
    </div>
    <div class="options-container"
         v-if="open">
      <div class="options item-option"
           v-for="(opt, i) in options"
           :key="i"
           @click="selectOption(opt)"
      > {{ opt }}
      </div>
    </div>
    <div class="container-selected-options">
      <div class="selected-options"
           v-for="(item,i) in selectedOptions"
           :key="i"
      >
        {{ item }}
        <span class="button-delete-option"
              @click="delOption(item)"
        >
           <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.8245 0.535925L13.6816 2.39308L2.36792 13.7068L0.510766 11.8496L11.8245 0.535925ZM2.68575 0.602339L13.9995 11.916L11.9022 14.0133L0.588517 2.69957L2.68575 0.602339Z"
            fill="black" fill-opacity="0.75"/>
      </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSelectorShow",
  data() {
    return {
      selectedOptions: [],
      currentSelect: '',
    }
  },
  props: {
    options: {
      type: Array,
    },
    open: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    selectOption(opt) {
      let i = this.selectedOptions.indexOf(opt)
      if (i === -1) this.selectedOptions.push(opt);
      this.$emit('updateSelect', this.selectedOptions);
      this.currentSelect = opt;
    },
    delOption(opt) {
      let i = this.selectedOptions.indexOf(opt)
      if (i !== -1) this.selectedOptions.splice(i, 1);
      this.$emit('updateSelect', this.selectedOptions);
    },
    openMenu() {
      this.$emit('openMenu', this.open);
    }
  },
  mounted() {
    this.currentSelect = this.options[0];
  }
}
</script>

<style scoped>
.selector-container {
  margin: 20px 20px 20px 0;
  min-width: 300px;
  max-width: 300px;
}
.container-selected-options {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
}
.options-container {
  background: #EEF4F8;
  border: 1px solid #BECBD4;
  position: relative;
  top: -14px;
  padding-top: 10px;
  border-radius: 6px;
}
.selector-button {
  border: 1px solid #BECBD4;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  background: #EEF4F8;
  position: relative;
  border-radius: 6px;
}
.options {
  background: #EEF4F8;
  border-top: 1px solid #BECBD4;
  border-radius: 0 0 6px 6px;
}
.options:hover {
  background: #e1eaef;
}
.item-option {
  padding: 13px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.selected-options {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.75);
  margin: 7px 10px 7px 0;
}
.button-delete-option {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
</style>