<template>
  <div>
    <div class="pages"
      v-if="$router.currentRoute.value.path.substring(0, 6) !== '/login'">

      <AppMessageNotice  :data="messageNotice" /> 

      <header class="header flex-start-center">
        <svg width="110" height="24" viewBox="0 0 110 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 23.2254V7.01046H5.82726V23.2254H0Z" fill="white"/>
          <path d="M15.6396 1.60547L20.0734 16.8492H20.2423L24.6761 1.60547H31.3057L24.1694 23.2254H16.1464L9.01008 1.60547H15.6396Z" fill="white"/>
          <path d="M33.4724 23.2254V7.01046H39.2997V23.2254H33.4724Z" fill="white"/>
          <path d="M51.9386 7.01046V11.2331H41.2553V7.01046H51.9386ZM43.3244 3.12562H49.1516V18.0105C49.1516 18.2357 49.1903 18.4257 49.2678 18.5805C49.3452 18.7283 49.4648 18.8409 49.6267 18.9183C49.7886 18.9887 49.9962 19.0239 50.2495 19.0239C50.4255 19.0239 50.6296 19.0028 50.8618 18.9606C51.1011 18.9183 51.277 18.8831 51.3896 18.855L52.2342 22.951C51.9738 23.0284 51.6008 23.1234 51.1152 23.236C50.6366 23.3486 50.0665 23.4225 49.405 23.4577C48.0678 23.5281 46.9453 23.3908 46.0374 23.046C45.1296 22.6941 44.4469 22.1416 43.9894 21.3886C43.532 20.6355 43.3103 19.6925 43.3244 18.5594V3.12562Z" fill="white"/>
          <path d="M54.3165 23.2254V7.01046H59.9748V10.093H60.1437C60.4393 8.95288 60.9073 8.11891 61.5477 7.59107C62.1952 7.06324 62.9518 6.79933 63.8174 6.79933C64.0708 6.79933 64.3206 6.82044 64.5669 6.86267C64.8203 6.89786 65.0631 6.95064 65.2954 7.02102V11.9932C65.0068 11.8947 64.6514 11.8208 64.2291 11.7715C63.8069 11.7222 63.4444 11.6976 63.1418 11.6976C62.5717 11.6976 62.058 11.8278 61.6005 12.0882C61.1501 12.3416 60.7947 12.7005 60.5343 13.165C60.2739 13.6224 60.1437 14.1608 60.1437 14.7801V23.2254H54.3165Z" fill="white"/>
          <path d="M67.2061 23.2254V7.01046H73.0334V23.2254H67.2061Z" fill="white"/>
          <path d="M81.8297 14.1045V23.2254H76.0024V7.01046H81.5341V10.093H81.703C82.0549 9.06548 82.6742 8.25966 83.5609 7.67553C84.4547 7.09139 85.4963 6.79933 86.6857 6.79933C87.8329 6.79933 88.8287 7.06324 89.6732 7.59107C90.5248 8.11187 91.1828 8.82972 91.6473 9.74462C92.1189 10.6595 92.3511 11.7046 92.3441 12.8799V23.2254H86.5168V14.1045C86.5238 13.3022 86.3197 12.6723 85.9045 12.2149C85.4963 11.7574 84.9263 11.5287 84.1943 11.5287C83.7158 11.5287 83.297 11.6343 82.9381 11.8454C82.5862 12.0495 82.3153 12.3451 82.1252 12.7321C81.9352 13.1122 81.8367 13.5696 81.8297 14.1045Z" fill="white"/>
          <path d="M99.739 23.4788C98.7044 23.4788 97.7895 23.3099 96.9942 22.9721C96.206 22.6272 95.5867 22.1064 95.1363 21.4097C94.6859 20.713 94.4607 19.8262 94.4607 18.7494C94.4607 17.8627 94.612 17.1061 94.9146 16.4798C95.2172 15.8464 95.6395 15.3291 96.1814 14.9279C96.7233 14.5268 97.3532 14.2206 98.071 14.0095C98.7959 13.7984 99.5771 13.6611 100.415 13.5978C101.322 13.5274 102.051 13.443 102.6 13.3444C103.156 13.2389 103.557 13.0946 103.803 12.9116C104.05 12.7216 104.173 12.4718 104.173 12.1621V12.1199C104.173 11.6976 104.011 11.3739 103.687 11.1487C103.363 10.9234 102.948 10.8108 102.441 10.8108C101.885 10.8108 101.432 10.934 101.08 11.1803C100.735 11.4196 100.527 11.7891 100.457 12.2888H95.0941C95.1644 11.3035 95.4776 10.3956 96.0336 9.56516C96.5966 8.72767 97.4165 8.05908 98.4933 7.5594C99.5701 7.05269 100.914 6.79933 102.526 6.79933C103.687 6.79933 104.729 6.93656 105.651 7.21104C106.573 7.47847 107.357 7.85499 108.005 8.34059C108.652 8.81916 109.145 9.38218 109.483 10.0297C109.828 10.6701 110 11.3668 110 12.1199V23.2254H104.553V20.9452H104.426C104.102 21.5504 103.708 22.0396 103.244 22.4126C102.786 22.7856 102.262 23.0565 101.671 23.2254C101.087 23.3943 100.443 23.4788 99.739 23.4788ZM101.639 19.8051C102.083 19.8051 102.498 19.7136 102.885 19.5306C103.279 19.3476 103.599 19.0837 103.845 18.7389C104.092 18.394 104.215 17.9753 104.215 17.4826V16.1314C104.06 16.1947 103.895 16.2545 103.719 16.3108C103.55 16.3671 103.367 16.4199 103.17 16.4692C102.98 16.5185 102.776 16.5642 102.558 16.6064C102.346 16.6487 102.125 16.6874 101.893 16.7226C101.442 16.7929 101.073 16.9091 100.784 17.0709C100.503 17.2258 100.291 17.4193 100.151 17.6515C100.017 17.8767 99.9501 18.1301 99.9501 18.4116C99.9501 18.862 100.108 19.2069 100.425 19.4462C100.742 19.6854 101.147 19.8051 101.639 19.8051Z" fill="white"/>
          <path d="M2.8714 5.32054C2.08317 5.32054 1.40755 5.06014 0.84453 4.53935C0.28151 4.01855 0 3.39219 0 2.66027C0 1.92834 0.28151 1.30198 0.84453 0.781189C1.40755 0.260396 2.08317 0 2.8714 0C3.66667 0 4.34229 0.260396 4.89827 0.781189C5.46129 1.30198 5.7428 1.92834 5.7428 2.66027C5.7428 3.39219 5.46129 4.01855 4.89827 4.53935C4.34229 5.06014 3.66667 5.32054 2.8714 5.32054Z" fill="#FF0000"/>
          <path d="M2.8714 5.32054C2.08317 5.32054 1.40755 5.06014 0.84453 4.53935C0.28151 4.01855 0 3.39219 0 2.66027C0 1.92834 0.28151 1.30198 0.84453 0.781189C1.40755 0.260396 2.08317 0 2.8714 0C3.66667 0 4.34229 0.260396 4.89827 0.781189C5.46129 1.30198 5.7428 1.92834 5.7428 2.66027C5.7428 3.39219 5.46129 4.01855 4.89827 4.53935C4.34229 5.06014 3.66667 5.32054 2.8714 5.32054Z" fill="#FF0000"/>
          <path d="M2.8714 5.32054C2.08317 5.32054 1.40755 5.06014 0.84453 4.53935C0.28151 4.01855 0 3.39219 0 2.66027C0 1.92834 0.28151 1.30198 0.84453 0.781189C1.40755 0.260396 2.08317 0 2.8714 0C3.66667 0 4.34229 0.260396 4.89827 0.781189C5.46129 1.30198 5.7428 1.92834 5.7428 2.66027C5.7428 3.39219 5.46129 4.01855 4.89827 4.53935C4.34229 5.06014 3.66667 5.32054 2.8714 5.32054Z" fill="#FF0000"/>
          <path d="M2.8714 5.32054C2.08317 5.32054 1.40755 5.06014 0.84453 4.53935C0.28151 4.01855 0 3.39219 0 2.66027C0 1.92834 0.28151 1.30198 0.84453 0.781189C1.40755 0.260396 2.08317 0 2.8714 0C3.66667 0 4.34229 0.260396 4.89827 0.781189C5.46129 1.30198 5.7428 1.92834 5.7428 2.66027C5.7428 3.39219 5.46129 4.01855 4.89827 4.53935C4.34229 5.06014 3.66667 5.32054 2.8714 5.32054Z" fill="#FF0000"/>
          <path d="M36.3939 5.32054C35.6056 5.32054 34.93 5.06014 34.367 4.53935C33.804 4.01855 33.5225 3.39219 33.5225 2.66027C33.5225 1.92834 33.804 1.30198 34.367 0.781189C34.93 0.260396 35.6056 0 36.3939 0C37.1891 0 37.8648 0.260396 38.4207 0.781189C38.9838 1.30198 39.2653 1.92834 39.2653 2.66027C39.2653 3.39219 38.9838 4.01855 38.4207 4.53935C37.8648 5.06014 37.1891 5.32054 36.3939 5.32054Z" fill="#37F3FF"/>
          <path d="M69.9173 5.32054C69.1291 5.32054 68.4534 5.06014 67.8904 4.53935C67.3274 4.01855 67.0459 3.39219 67.0459 2.66027C67.0459 1.92834 67.3274 1.30198 67.8904 0.781189C68.4534 0.260396 69.1291 0 69.9173 0C70.7126 0 71.3882 0.260396 71.9442 0.781189C72.5072 1.30198 72.7887 1.92834 72.7887 2.66027C72.7887 3.39219 72.5072 4.01855 71.9442 4.53935C71.3882 5.06014 70.7126 5.32054 69.9173 5.32054Z" fill="#33DB18"/>
          <path d="M0 23.2254V7.01046H5.82726V23.2254H0Z" fill="white"/>
          <path d="M15.6396 1.60547L20.0734 16.8492H20.2423L24.6761 1.60547H31.3057L24.1694 23.2254H16.1464L9.01008 1.60547H15.6396Z" fill="white"/>
          <path d="M33.4724 23.2254V7.01046H39.2997V23.2254H33.4724Z" fill="white"/>
          <path d="M51.9386 7.01046V11.2331H41.2553V7.01046H51.9386ZM43.3244 3.12562H49.1516V18.0105C49.1516 18.2357 49.1903 18.4257 49.2678 18.5805C49.3452 18.7283 49.4648 18.8409 49.6267 18.9183C49.7886 18.9887 49.9962 19.0239 50.2495 19.0239C50.4255 19.0239 50.6296 19.0028 50.8618 18.9606C51.1011 18.9183 51.277 18.8831 51.3896 18.855L52.2342 22.951C51.9738 23.0284 51.6008 23.1234 51.1152 23.236C50.6366 23.3486 50.0665 23.4225 49.405 23.4577C48.0678 23.5281 46.9453 23.3908 46.0374 23.046C45.1296 22.6941 44.4469 22.1416 43.9894 21.3886C43.532 20.6355 43.3103 19.6925 43.3244 18.5594V3.12562Z" fill="white"/>
          <path d="M54.3165 23.2254V7.01046H59.9748V10.093H60.1437C60.4393 8.95288 60.9073 8.1189 61.5477 7.59107C62.1952 7.06324 62.9518 6.79933 63.8174 6.79933C64.0708 6.79933 64.3206 6.82044 64.5669 6.86267C64.8203 6.89786 65.0631 6.95064 65.2953 7.02102V11.9932C65.0068 11.8947 64.6514 11.8208 64.2291 11.7715C63.8069 11.7222 63.4444 11.6976 63.1418 11.6976C62.5717 11.6976 62.058 11.8278 61.6005 12.0882C61.1501 12.3416 60.7947 12.7005 60.5343 13.165C60.2739 13.6224 60.1437 14.1608 60.1437 14.7801V23.2254H54.3165Z" fill="white"/>
          <path d="M67.2061 23.2254V7.01046H73.0334V23.2254H67.2061Z" fill="white"/>
          <path d="M81.8297 14.1045V23.2254H76.0024V7.01046H81.5341V10.093H81.703C82.0549 9.06548 82.6742 8.25966 83.5609 7.67553C84.4547 7.09139 85.4963 6.79933 86.6857 6.79933C87.8329 6.79933 88.8287 7.06324 89.6732 7.59107C90.5248 8.11187 91.1828 8.82972 91.6473 9.74462C92.1189 10.6595 92.3511 11.7046 92.3441 12.8799V23.2254H86.5168V14.1045C86.5238 13.3022 86.3197 12.6723 85.9045 12.2149C85.4963 11.7574 84.9263 11.5287 84.1943 11.5287C83.7158 11.5287 83.297 11.6343 82.9381 11.8454C82.5862 12.0495 82.3153 12.3451 82.1252 12.7321C81.9352 13.1122 81.8367 13.5696 81.8297 14.1045Z" fill="white"/>
          <path d="M99.739 23.4788C98.7044 23.4788 97.7895 23.3099 96.9942 22.9721C96.206 22.6272 95.5867 22.1064 95.1363 21.4097C94.6859 20.713 94.4607 19.8262 94.4607 18.7494C94.4607 17.8627 94.612 17.1061 94.9146 16.4798C95.2172 15.8464 95.6395 15.3291 96.1814 14.9279C96.7233 14.5268 97.3532 14.2206 98.071 14.0095C98.7959 13.7984 99.5771 13.6611 100.415 13.5978C101.322 13.5274 102.051 13.443 102.6 13.3444C103.156 13.2389 103.557 13.0946 103.803 12.9116C104.05 12.7216 104.173 12.4718 104.173 12.1621V12.1199C104.173 11.6976 104.011 11.3739 103.687 11.1487C103.363 10.9234 102.948 10.8108 102.441 10.8108C101.885 10.8108 101.432 10.934 101.08 11.1803C100.735 11.4196 100.527 11.7891 100.457 12.2888H95.0941C95.1644 11.3035 95.4776 10.3956 96.0336 9.56516C96.5966 8.72767 97.4165 8.05908 98.4933 7.5594C99.5701 7.05269 100.914 6.79933 102.526 6.79933C103.687 6.79933 104.729 6.93656 105.651 7.21104C106.573 7.47847 107.357 7.85499 108.005 8.34059C108.652 8.81916 109.145 9.38218 109.483 10.0297C109.828 10.6701 110 11.3668 110 12.1199V23.2254H104.553V20.9452H104.426C104.102 21.5504 103.708 22.0396 103.244 22.4126C102.786 22.7856 102.262 23.0565 101.671 23.2254C101.087 23.3943 100.443 23.4788 99.739 23.4788ZM101.639 19.8051C102.083 19.8051 102.498 19.7136 102.885 19.5306C103.279 19.3476 103.599 19.0837 103.845 18.7389C104.092 18.394 104.215 17.9753 104.215 17.4826V16.1314C104.06 16.1947 103.895 16.2545 103.719 16.3108C103.55 16.3671 103.367 16.4199 103.17 16.4692C102.98 16.5185 102.776 16.5642 102.558 16.6064C102.346 16.6487 102.125 16.6874 101.893 16.7226C101.442 16.7929 101.073 16.9091 100.784 17.0709C100.503 17.2258 100.291 17.4193 100.151 17.6515C100.017 17.8767 99.9501 18.1301 99.9501 18.4116C99.9501 18.862 100.108 19.2069 100.425 19.4462C100.742 19.6854 101.147 19.8051 101.639 19.8051Z" fill="white"/>
          <path d="M2.8714 5.32054C2.08317 5.32054 1.40755 5.06014 0.84453 4.53935C0.28151 4.01855 0 3.39219 0 2.66027C0 1.92834 0.28151 1.30198 0.84453 0.781189C1.40755 0.260396 2.08317 0 2.8714 0C3.66667 0 4.34229 0.260396 4.89827 0.781189C5.46129 1.30198 5.7428 1.92834 5.7428 2.66027C5.7428 3.39219 5.46129 4.01855 4.89827 4.53935C4.34229 5.06014 3.66667 5.32054 2.8714 5.32054Z" fill="#FF0000"/>
          <path d="M2.8714 5.32054C2.08317 5.32054 1.40755 5.06014 0.84453 4.53935C0.28151 4.01855 0 3.39219 0 2.66027C0 1.92834 0.28151 1.30198 0.84453 0.781189C1.40755 0.260396 2.08317 0 2.8714 0C3.66667 0 4.34229 0.260396 4.89827 0.781189C5.46129 1.30198 5.7428 1.92834 5.7428 2.66027C5.7428 3.39219 5.46129 4.01855 4.89827 4.53935C4.34229 5.06014 3.66667 5.32054 2.8714 5.32054Z" fill="#FF0000"/>
          <path d="M2.8714 5.32054C2.08317 5.32054 1.40755 5.06014 0.84453 4.53935C0.28151 4.01855 0 3.39219 0 2.66027C0 1.92834 0.28151 1.30198 0.84453 0.781189C1.40755 0.260396 2.08317 0 2.8714 0C3.66667 0 4.34229 0.260396 4.89827 0.781189C5.46129 1.30198 5.7428 1.92834 5.7428 2.66027C5.7428 3.39219 5.46129 4.01855 4.89827 4.53935C4.34229 5.06014 3.66667 5.32054 2.8714 5.32054Z" fill="#FF0000"/>
          <path d="M2.8714 5.32054C2.08317 5.32054 1.40755 5.06014 0.84453 4.53935C0.28151 4.01855 0 3.39219 0 2.66027C0 1.92834 0.28151 1.30198 0.84453 0.781189C1.40755 0.260396 2.08317 0 2.8714 0C3.66667 0 4.34229 0.260396 4.89827 0.781189C5.46129 1.30198 5.7428 1.92834 5.7428 2.66027C5.7428 3.39219 5.46129 4.01855 4.89827 4.53935C4.34229 5.06014 3.66667 5.32054 2.8714 5.32054Z" fill="#FF0000"/>
          <path d="M36.3939 5.32054C35.6056 5.32054 34.93 5.06014 34.367 4.53935C33.804 4.01855 33.5225 3.39219 33.5225 2.66027C33.5225 1.92834 33.804 1.30198 34.367 0.781189C34.93 0.260396 35.6056 0 36.3939 0C37.1891 0 37.8648 0.260396 38.4207 0.781189C38.9838 1.30198 39.2653 1.92834 39.2653 2.66027C39.2653 3.39219 38.9838 4.01855 38.4207 4.53935C37.8648 5.06014 37.1891 5.32054 36.3939 5.32054Z" fill="#37F3FF"/>
          <path d="M69.9173 5.32054C69.1291 5.32054 68.4534 5.06014 67.8904 4.53935C67.3274 4.01855 67.0459 3.39219 67.0459 2.66027C67.0459 1.92834 67.3274 1.30198 67.8904 0.781189C68.4534 0.260396 69.1291 0 69.9173 0C70.7126 0 71.3882 0.260396 71.9442 0.781189C72.5072 1.30198 72.7887 1.92834 72.7887 2.66027C72.7887 3.39219 72.5072 4.01855 71.9442 4.53935C71.3882 5.06014 70.7126 5.32054 69.9173 5.32054Z" fill="#33DB18"/>
        </svg>
        <span class="header-message">Личный кабинет  суперадминистратора</span>
        <AppButton style="margin-left:200px; " @click="exit()">Выход</AppButton>
      </header>
    </div>
      <main class="main flex-between-start">
        <div class="navigation-page" v-if="$router.currentRoute.value.path.substring(0, 6) !== '/login'">
          <app-button-menu
              v-for="(item, i) in menuData"
              :key="i"
              @click="selectItemMenu(i)"
              :image="item.image"
              :header="item.header"
              :link="item.link"
              :is_active = "item.state"
          />
        </div>
        <div class="page-view">
          <router-view></router-view>
        </div>
      </main>
  </div>
</template>

<script>
import {shops, asks, clients, setting, request} from '@/assets/svg'
//import { getMemoedVNodeCall } from '@vue/compiler-core'
import apiGlobal from '@/api/api'

export default {
  name: "AppPages",
  data() {
    return {
      messageNotice: {
        currentHeader: 'Ошибка 123',
        currentMessage: 'Отправьте отчёт об ошибке и мы исправим её в ближайшее время.',
        currentStatus: 'error',
        showNow: false,
      },
      menuData: [],
      allMenuData: [
        {
          name: 'shops',
          image: shops,
          header: 'Магазины',
          link: '/shops',
          state: false,
        },
        {
          name: 'vizits',
          image: shops,
          header: 'Визитки',
          link: '/vizits',
          state: false,
        },
        {
          name: 'request',
          image: request,
          header: 'Заявки',
          link: '/request',
          state: false,
        },
        {
          name: 'asks',
          image: asks,
          header: 'Обращения',
          link: '/asks',
          state: false,
        },
        {
          name: 'clients',
          image: clients,
          header: 'Клиенты',
          link: '/clients',
          state: false,
        },
        {
          name: 'setting',
          image: setting,
          header: 'Настройки',
          link: '/setting',
          state: false,
        },
      ],
    }
  },
  created() {
        console.log('created')
        document.title = "Личный кабинет  суперадминистратора"
        if (!localStorage.getItem('token') || localStorage.getItem('token')=="") {
            this.$router.replace("/login");
            return
        }
        this.getMe()
        this.getShopCategories()
        
        //this.getData()
    },
  methods: {
    exit(){
      localStorage.removeItem('token')
      window.open('/','_self',false);
    },
    async getShopCategories(){
      let response = await apiGlobal.getShopCategories();

      localStorage.setItem("categories",JSON.stringify(response.data.data.message))
    },
    async getMe(){
        let response = await apiGlobal.getMe();
        let widgets = response.data.data.widgets
        for (let index = 0; index < widgets.length; index++) {
          let element = this.allMenuData.find(e=>e.name==widgets[index].slug)
          if (!element) continue
          this.menuData.push(element)
        }
        this.$router.replace(this.menuData[0].link);
        this.menuData[0].state = true;
        console.log(this.menuData,"menuData")
    },
    selectItemMenu(i) {
      this.menuData.forEach(itm=>itm.state = false);
      this.menuData[i].state = true;
    },
    showMessageNotice(header, message, status) {
       this.messageNotice ={
        currentMessage:message,
        currentHeader: header,
        currentStatus: status,
        showNow: true,
       }
      
       setTimeout(()=>this.messageNotice.showNow = false, 3000)
   }
 },
 provide() {
   return {showMessageNotice: this.showMessageNotice}
 }
}
</script>

<style lang="scss" scoped>
@import "@/style/_vars.scss";

.header {
  background: $main-blue;
  padding: 11px 20px;
}

.main {
  background: $main-background;
}

.navigation-page {
  min-width: 160px;
  background: $main-background;
}

.page-view {
  width: calc(100% - 180px);
  height: 100%;
  margin: 30px;
}

.header-message {
  margin-left: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #FFFFFF;
}

</style>