<template>
    <div class="input-content">
        <label class="input__title" for="">
            <slot name="title"></slot>
        </label>
        <div class="input__content">
            <input class="input"
            :readonly="readonly"
            :type="typeInput"
            :value="modelValue"
                   :placeholder="placeholder"
            @input="updateInput">
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppInput',
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        typeInput: {
            type: String,
            default: 'text'
        },
        modelValue: {
            type: [String, Number]
        },
      placeholder: {
        type: [String, Number]
      }
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-content {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .input__title {
        color: #7E7E7E;
        font-size: 12px;
    }
    .input {
        background: #EEF4F8;
        border: 1px solid #E4E7ED;
        border-radius: 6px;
        padding: 11px;
        width: 100%;
        &[type='email'] {
            text-decoration: underline;
        }
    }
</style>