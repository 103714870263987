<template>
<div class="table-footer flex-between-start" v-if="meta">
      <AppSelect2
        :selectPage="meta.currentPage"
        :options="meta.pages"
        @selectVariant="selectPage"
      />
      <div class="turn-pages-container flex-between-center">
        <AppButtonIcon
            @click="goNext(-1)"
            :style="backButton"
            :icon="arrow_grey_left"
            :onlyIcon="true"
        />
        <AppButtonIcon
            @click="goNext(1)"
            :style="nextButton"
            :icon="arrow_grey_right"
            :onlyIcon="true"
        />
      </div>
    </div>
</template>

<script>

import {arrow_grey_right, arrow_grey_left} from '@/assets/svg';
export default {
    name: 'AppNavigationBottom',
    props: {
        meta:{
            type: Object,
            required: true
        },
    },
    setup() {
    return {arrow_grey_right, arrow_grey_left};
  },
    computed: {
        backButton(){
            if(this.meta.currentPage>1) return {display:"block"};
            return {display:"none"};
        },
        nextButton(){
            if(this.meta.currentPage<this.meta.pages) return {display:"block"};
            return {display:"none"};
        }
    }, methods:{
        selectPage(value) {
            this.$emit("goPage", value)
        },
        goNext(el){
                let check = (this.meta.currentPage+el<=this.meta.pages && this.meta.currentPage+el>=1)
                if (!check) return
                this.$emit("goPage", this.meta.currentPage+el)
        }

    }
}
</script>

<style lang="scss">

.page-button {
  font-size: 11px;
}

.open-close {
  transform: rotateZ(180deg);
  transition: .2s;
}

.customize-table {
  border-radius:  6px 6px 0 0;

  .vue3-easy-data-table__main {
    border-radius: 6px 6px 0 0;
  }
  .vue3-easy-data-table__footer {
    border-radius: 0 0 6px 6px;
  }
}

.turn-pages-container {
  min-width: 60px;
  margin-right: 50px;
}


.customize-header-items-table {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  --easy-table-header-font-color: rgba(0, 0, 0, 0.5);

  &.first-header .header {
    justify-content: center !important;
  }
}

.vue3-easy-data-table__header th:first-child,
.vue3-easy-data-table__body td.can-expand {
  display: none;
}


.table-shops {
  .logo-img {
    margin: 15px 5px;
  }
  .table-footer {
    margin: 14px 0 10px 0;
  }
}



.header-shop-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.75);
  margin: 0;
}

.header-shop-link {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #20A0FF;
}

.button-go-shop {
  margin: 10px 20px  10px 0;
}

.vue3-easy-data-table__body .expand {
  background: rgba(228, 231, 237, 0.5)!important;
}

.static-sets-containers {
  position: relative;
}

.separator {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  margin: auto;
  top: 97px;
  background: rgb(161 174 183 / 73%);
}

</style>