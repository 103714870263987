<template>
  <app-pages/>
</template>

<script>
import AppPages from '@/pages/AppPages';

export default {
  name: 'App',
  components: {
    AppPages
  },

}
</script>

<style lang="scss">

</style>
