<template>
    <div>
      <button
          :style="styleEdit"
          class="button"
          :class="styleButton">
        <slot></slot>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AppButton',
    props: {
      styleEdit:{
        type:String
      },
      styleButton: {
        type: String,
        required: false
      }
    }
  }
  </script>
  
  <style lang="scss">
  .button {
    background: red;
    border-radius: 6px;
    color: white;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: center;
    transition: 200ms;
    width: 100px;
      &:hover {
        background: #FF4444;
      }
      &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FF0000;;
      }
      &._no-active {
        background: #B2BCC4;
      }
      &._active {
        background: #B90000;
      }
  }
  </style>