<template>
  <div class="statistic-set-container flex-between-center flex-wrap">
    <h5 class="header-stats">{{ items.header }}</h5>
    <statisticItem
        v-for="(item, i) in items.stats"
        :key="i"
        :valueStat="item.value"
        :valueStatAdd="item.add"
        :nameStat="item.label"
        :notes="item.notes"
    /> 

  </div>
</template>

<script>
import statisticItem from './statisticItem';

export default {
  components: {
    statisticItem
  },

  data() {
    return {

    }
  },
  props: {
    items: {
      type: Object
    }
  },
  name: "statisticSet"
}
</script>

<style scoped>

.statistic-set-container {
  margin: 10px 20px;
}

.header-stats {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 10px 0;
}

</style>