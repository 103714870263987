<template>
  <div>
    <div class="add-find-filter flex-start-center">
      <AppButtonIcon
          style="margin-left:5px"
          :styleButton="'_red'" 
          :icon="plus"
          @click="addVizit('create')"
      >
        Добавить визитку
      </AppButtonIcon>
    
      <AppSearch class="find-shop"
          :placeholder="'Поиск'" 
          :value="search"
          @findModels="findModels"
      />
      <AppButtonIcon
          :styleButton="'_blue'"
          :icon="filter"
          :onlyIcon="true"
      />
    </div>
    <div style="display: flex;align-items:center;"> <span>Категории: </span>
      <AppButtonIcon
        
          style="margin:5px; font-size: 12px;"
          :styleButton="'_blue'"
          @click="categoryFilter(null)"
      >
        Все
      </AppButtonIcon>
      <AppButtonIcon
          v-for="category in categories " :key="category.id"
          style="margin:5px; font-size: 12px;"
          :styleButton="'_blue'"
          @click="categoryFilter(category.id)"
      >
      {{ category.title }}
      </AppButtonIcon>
    </div>
    <TableVizits :shops="shops" :meta="metaShops" @goPage="goPage" />
  </div>
</template>

<script>

import {plus, filter, calendar} from '@/assets/svg';
//import statisticSetGlobal from './listShops/statisticSetGlobal';
import TableVizits from "./listVizits/TableVizits";
import api from "./api"


export default {
  name: "PageVizits",
  components: {
    TableVizits,
    //statisticSetGlobal
  },
  data() {
    return {
      categories: JSON.parse(localStorage.getItem("categories")) || [],
      page: 1,
      search:"",
      pageSize:10,
      category_id: null,
      shops: [],
      shopsFavourites: [],
      month: 'июнь',
      statesFilters: [],
      year: '2077',
      mainStats: [
        {
          "header": "Магазины/Визитки",
          "stats": [
            {
              "label": "Всего",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
            {
              "label": "Отключены",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
          ]
        },
        {
          "header": "Товары",
          "stats": [
            {
              "label": "Всего товаров",
              "add": 0,
              "notes": "Error!",
              "value": 2
            }
          ]
        },
        {
          "header": "Клиенты/посетители",
          "stats": [
            {
              "label": "Кленты",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
            {
              "label": "Доставка",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
            {
              "label": "Посетители",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
          ]
        },
      ],
    }
  },
  mounted() {
    this.getModels();
  },
  methods:{
    findModels(search){
      this.search = search
      this.getModels();
    },
    categoryFilter(category_id){
      this.category_id = category_id
      this.getModels();
    },
    controlMenu(i, state) {
      this.statesFilters.forEach((item,i,arr) => arr[i] = false);
      this.statesFilters[i] = !state;
    },
    addVizit(value){
      this.$router.push({ name: 'addVizit',  params: { id: value }})
    },
    goPage(page){
      this.page = page
      this.getModels()
    },
    
    async getModels(){
      let data = {
        page: this.page, 
        pageSize: this.pageSize, 
        category_id: this.category_id,
        search:this.search
      }
      await api.getModels(data).then((response) => {
              this.shops = response.data.data
              this.metaShops = response.data.meta
    
              /*
              if (response.data.message){
                this.showMessageNotice('', response.data.message,'success')
              }
              */
              })
              /*
              .catch((error) => {
                  if (error.response.data.message)
                    this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
              })
              */
        
    },
  },
  watch:{
 
  },
  setup() {
    return {plus, filter, calendar}
  }

}
</script>

<style lang="scss" scoped>

.find-shop {
  width: 420px;
  margin: 0 20px;
}

.add-find-filter {
  margin-bottom: 16px;
}

</style>