<template>
<div class="stat-container">
  <div class="stat-name">
    {{ nameStat }}
  </div>
 
  <div class="stat-value">
    
    <div class="stat-value-add">
      {{ valueStatAdd }}
    </div>

    {{ valueStat }}
  </div>
   <!--
  <AppIconAttention
      class="states-notes" 
      v-if="notes"
      :message="notes"
  />
   -->
</div>
</template>

<script>
export default {
  name: "statisticItem",
  props: {
    valueStat: {
      type: String
    },
    valueStatAdd: {
      type: String
    },
    nameStat: {
      type: String
    },
    notes: {
      type: String
    },
  }
}
</script>

<style scoped>

.stat-container {
  display: inline-block;
}

.stat-value {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.75);
  display: inline-block;
}

.stat-value-add {
  position: absolute;
  top: -5px;
  left: calc(100% + 5px);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #169F00;
  white-space: nowrap;
}

.stat-name {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 7px;
}

.states-notes {
  margin-left: 30px;
  bottom: -2px;
}

</style>