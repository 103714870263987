import { createStore } from 'vuex';
import login from '@/store/modules/login';

const BACKEND_URL = 'https://admin.ivitrina.store/api/v1'; 
export const BACKEND_URL_LOGIN = `${BACKEND_URL}/auth/login`;


export default createStore({
    modules: {
        login,
    }
})