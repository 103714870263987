<template>
    <section class="section-product-edit product-edit section">
        <div class="container">
             <!--
                <div class="flex-container">
                    <AppSectionTitle>
                Клиент
                    </AppSectionTitle>
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="goVizits()"
                    >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z" fill="#8EA2B0"/>
                    </svg>
                </div>
            -->
            <div class="product-edit__content">
            <!--
              <div class="product-edit__info"> 
                    <AppInput
                        :readonly="model.id"
                        inputRequired
                        :subSymbol="''"
                        pattern="name"
                        :typeInput="'string100'"
                        v-model="client.name"
                        > 
                        <template v-slot:title>
                            Имя пользователя
                        </template>
                    </AppInput>
  
                    <AppInput
                        :readonly="model.id"
                        inputRequired
                        :subSymbol="''"
                        pattern="name"
                        :typeInput="'string100'"
                        v-model="client.email"
                        > 
                        <template v-slot:title>
                           Email
                        </template>
                    </AppInput>
                    <AppInput
                        :readonly="model.id"
                        inputRequired
                        :subSymbol="''"
                        pattern="name"
                        :typeInput="'string100'"
                        v-model="client.password"
                        > 
                        <template v-slot:title>
                            Пароль
                        </template>
                    </AppInput>
              </div>
            -->
              <AppSectionTitle>
                Визитка
              </AppSectionTitle>
              <div class="product-edit__info"> <!--основные параметры-->
                    <AppInput
                        inputRequired
                        :subSymbol="''"
                        pattern="name"
                        :typeInput="'string100'"
                        v-model="model.name"
                        > 
                        <template v-slot:title>
                          Название
                        </template>
                    </AppInput>
                    <AppInput
                        inputRequired
                        :subSymbol="''"
                        pattern="name"
                        :typeInput="'string100'"
                        v-model="model.button"
                        > 
                        <template v-slot:title>
                          Кнопка в телеграм
                        </template>
                    </AppInput>
  
                    <AppInput
                        inputRequired
                        :subSymbol="''"
                        pattern="name"
                        v-model="model.text"
                        > 
                        <template v-slot:title>
                          Привественное сообщение после отправки /start
                        </template>
                    </AppInput>
                
                    <AppSelectElement
                        v-if="categories"
                        v-model="model.category_id"
                        :items="categories">
                            <template v-slot:title>
                               Категория
                            </template>
                    </AppSelectElement>
                    <AppInput
                        inputRequired
                        :subSymbol="''" 
                        pattern="name"
                        v-model="model.token"
                        > 
                        <template v-slot:title>
                        Токен (при редактировании не заполняется, если токен не изменился)
                        </template>
                    </AppInput>
                    <AppInput
                        inputRequired
                        :subSymbol="''"
                        pattern="name"
                        :typeInput="'string100'"
                        v-model="model.link"
                        > 
                        <template v-slot:title>
                         Ссылка на страницу
                        </template>
                    </AppInput>
              </div>
              <div class="product-edit__interaction">
                        <AppButton
                            :styleEdit="'width: 100%;'"
                            :styleButton="'_red'"
                            @click="save()">
                            {{(model.id)?"Редактировать визитку":"Добавить Визитку"}}
                        </AppButton>
                    </div>
            </div>
            
        </div>
    </section>
  </template>
  
  <script>
  import api from "./api";
  
  
  export default {
    name: "AddVizit",
    props:{
        id:{
            type:[String, Number]
        }
    },
    data() {
      return {
        categories: JSON.parse(localStorage.getItem("categories")) || [],
        blockButton: false,
        model: {},
        client: {},
        user: null,
      }
    },
      mounted() { 
        this.getModel() 
      },
      methods: {
        getModel(){
            api.getModel(this.id)
                .then((response) => {
                    this.model = {
                        id:response.data.data.id,
                        category_id:response.data.data.category_id,
                        name:response.data.data.name,
                        text:response.data.data.telegram.start_message,
                        button:response.data.data.telegram.button,
                        link:response.data.data.telegram.web_app,
                    },
                    this.client = {
                        name:response.data.data.user.name,
                        email:response.data.data.user.email,
                    }
                    console.log(response.data)
                })
                .catch(() => {  this.goVizits  });
        },
        goVizits(){
            this.$router.push({ name: 'Vizits'})
        },
        save(){
            if (this.blockButton) return
            this.blockButton = true
            let data = {client:this.client, vizit:this.model}
            let apiMethod = api.createModel
            if (this.model.id){
                data = this.model;
                apiMethod = api.updateModel
            }
            apiMethod(data).then((response) => {
                this.goVizits()
                if (response.data.message){
                    this.showMessageNotice('', response.data.message,'success')
                    //if (!this.model.id) this.$router.push({ name: 'addVizit',  params: { id: response.data.id }})
                }
                }).catch((error) => {
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                    if (error.response.data.errors)
                        this.showMessageNotice('Ошибка 09', JSON.stringify(error.response.data.errors),'error')
                })

            this.blockButton = false
            },
           
        },
  
    watch: {
    },
    inject: ['showMessageNotice'],
  }
  
  </script>
  
  
  <style lang="scss" scoped>
    .product-edit__content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 12px 16px 13px;
        & > * {
            border-top: 1px solid var(--global_block_line);
            padding-top: 20px;
            &:first-child {
                border-top: 0;
                padding-top: 0;
            }
        }
    }
    .product-edit__status {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
    }
    .product-edit__status_ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 13px;
    }
    .product-edit__info {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .product-edit__inputs {
        display: flex;
        column-gap: 15px;
    }
    .product-edit__add-form {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
    .product-edit__add-form_show {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px;
    }
  
    .flex-container svg {
      cursor: pointer;
    }
  
    .product-edit__show {
        row-gap: 10px;
    }
    .product-edit__images {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 10px;
    }
    .product-edit__category {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
  
    .promo {
      display: flex;
      justify-content: space-between;
      align-items: end;
    }
    .product-edit__bottom {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
    }
    .product-edit__digital__inner {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }
    .product-edit__interaction {
        margin: 16px auto 0;
        width: 95%;
        height: 40px;
    }
  </style>