<template>
  <router-link class="button-menu"
               :class="is_active ? '__active' : ''"
               :to="link"
  >
    <div class="flex-start-center">
      <div class="button-menu_img flex-center"
           v-html="image"
      ></div>
      <div>{{ header }}</div>
    </div>
  </router-link>
</template>
 
<script>
export default {
  name: "AppButtonMenu",
  data() {
    return {}
  },
  props: {
    image: {
      type: String,
    },
    is_active: {
      type: Boolean,
      default() {
        return false;
      }
    },
    header: {
      type: String,
    },
    link: {
      type: String,
    },
  }
}
</script>

<style lang="scss">
.button-menu {
  color: #8EA2B0;
  font-size: 12px;
  padding: 15px;
  margin-bottom: 2px;
  background: white;
  cursor: pointer;
  display: block;

  &.__active .button-menu_img svg path {
    fill: #20A0FF;
  }

  &.__active {
    color: #20A0FF;
    background: none;
  }
}

.button-menu .button-menu_img {
  margin: 0 12px;
  min-width: 24px;
}

</style>