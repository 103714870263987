<template>
  <div class="table-shops">
    <EasyDataTable
        :headers="headers"
        :items="shops"
        table-class-name="customize-table"
        :header-item-class-name="headerCustomize"
        :hide-footer="true"
    >
      <template>
      <!--#item-logoImg="item"-->


      <img style="width:64px; margin:5px" v-if="item.data.icon" :src="'https://cabinet.ivitrina.store/media/full/'+item.data.icon"/>
      <img style="width:64px; margin:5px" v-else src=" "/>
      </template>
      <template
          #item-name="{name, telegram}"
      >
        <div>
          <h3 class="header-shop-name">{{ name }}</h3>
          <a :href="telegram.link" v-if="telegram" class="header-shop-link" target="_blank">{{telegram.link}}</a>
        </div>
      </template>
     
      <template
          #item-paymentSystems="item"
      >
        <span   v-for="(payment_system) in item.payment_systems"
              :key="payment_system.id"
              :items="stat"> {{payment_system.title+", "}}</span>
      </template>
      <template
          #item-buttons="item"
      >
        <div class="flex-start-center">
          <AppButtonIcon
              class="button-go-shop"
              :styleButton="'_clear-blue'"
              @click="go(item)"
          >
           {{(item.type_id==1)?'Перейти в магазин':"Редактировать"}}
          </AppButtonIcon>
         
          <AppButtonIcon
              :styleButton="'_blue'"
          >
            Статистика
          </AppButtonIcon>
          
        </div>
      </template>
      <template
          #item-button_open
      >
        <svg @click="openClose" width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.405267 0.629146C1.02342 -0.112651 2.12588 -0.212885 2.86768 0.405267L9.39223 5.84229L15.9167 0.405294C16.6585 -0.212859 17.761 -0.112625 18.3792 0.629173C18.9973 1.37097 18.8971 2.47343 18.1553 3.09158L11.1722 8.91069C11.106 9.07334 11.0139 9.2287 10.8955 9.37083C10.5131 9.8297 9.94538 10.0431 9.39236 9.99276C8.83924 10.0432 8.27139 9.82981 7.88894 9.37085C7.77048 9.22871 7.67841 9.07332 7.61217 8.91063L0.629146 3.09155C-0.112651 2.4734 -0.212885 1.37094 0.405267 0.629146Z" fill="black"/>
        </svg>
      </template>
     
      <template #expand="item">
        <div class="static-sets-containers flex-start-center flex-wrap">
         <!--<shop-peoples-chart :shop-id="item.id" :created_at="item.created_at" v-if="item.id"/> --> 
        
         
          <statisticSet
              v-for="(stat, i) in stats"
              :key="i"
              :items="item[stat]"
          />
          <div>
          Код владельца: {{ item.code }}<br>
          Код администратора: {{ item.code2 }}
          </div>
          
          <AppButtonIcon
              class="button-go-shop"
              style="margin-left: 10px;"
              :styleButton="'_clear-red'"
              @click="deleteItem(item)"
          >
          Удалить витрину
          </AppButtonIcon>
        </div>
      </template>
   
    </EasyDataTable>
    <AppNavigationBottom v-if="!hiddenPagin"  :meta="meta" @goPage="goPage"/>
  <!--  <AppCalendar/>  -->
  </div>
</template>
 
<script>
import api from "../api"
import statisticSet from './statisticSet';
//import ShopPeoplesChart from './ShopPeoplesChart';
//import AppCalendar from "@/components/AppCalendar";
import AppButtonIcon from "@/components/UI/AppButtonIcon";
import {arrow_grey_right, arrow_grey_left} from '@/assets/svg';
//import AppSelect from "@/components/UI/AppSelect";

export default {
  components: {
   // ShopPeoplesChart,
   // AppSelect,
    AppButtonIcon,
   // AppCalendar,
    statisticSet
  },
  setup() {
    return {arrow_grey_right, arrow_grey_left};
  },
  props: {
      hiddenPagin:{
        type: Boolean,
        default: false,
      },
      meta:{
        type: Object,
          default: () => {},
      },
      shops: {
          type: Array,
          default: () => [],
      },
    },
  data() {
    return {
      headers: [
        {text: "Лого", value: "logoImg"},
        {text: "Название магазина, ссылка", value: "name"},
        {text: "Дата регистрации", value: "created_at"},
        {text: "Тарифный план ", value: "plan"},
        {text: "Способ приема платежей", value: "paymentSystems"},
        {text: "", value: "buttons"},
        {text: "", value: "button_open"},
      ],
      stats:[
        "product_stats","people_stats","order_stats",
      ],
      currentPage: 1,
      totalPage: 1,
    }
  },
  methods: {
    deleteItem(item){
      let text = `Вы действиельно ходите удалить витрину ${item.name}? \n 1. После удаления вместо витрины клиента будет отбражаться наша iVitrina. \n 2. Бот перестанет отвечать на сообщения \n 3. Витрина исчезнет из супер админки`
      if (confirm(text)) {
        api.deleteItem(item.id).then((response) => {
                this.showMessageNotice('Ошибка 09', response.data.data.message,'success')
                this.$emit('goPage', 1)
              })
              .catch((error) => {
                  if (error.response.data.message)
                    this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
              })
        }
      else console.log("not")

    },
    goPage(page) {
      this.$emit('goPage', page)
    },
    
    go(item){
      if (item.type_id==3){
        this.$router.push({ name: 'addVizit',  params: { id: item.id }})
        return
      }
      window.open("https://cabinet.ivitrina.store/admin_redirect?shop_id="+item.id)
    },
    headerCustomize(item, index) {
      let classes = 'customize-header-items-table';
      if (index === 1) classes += ' first-header';
      return classes;
    },
    openClose(event) {
      event.currentTarget.classList.toggle('open-close')
      event.currentTarget.parentNode.parentNode.firstElementChild.click();
    },
  },
  mounted() {

  },
  inject: ['showMessageNotice'],
}

</script>

<style lang="scss">

.page-button {
  font-size: 11px;
}

.open-close {
  transform: rotateZ(180deg);
  transition: .2s;
}

.customize-table {
  border-radius:  6px 6px 0 0;

  .vue3-easy-data-table__main {
    border-radius: 6px 6px 0 0;
  }
  .vue3-easy-data-table__footer {
    border-radius: 0 0 6px 6px;
  }
}

.turn-pages-container {
  min-width: 60px;
}


.customize-header-items-table {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  --easy-table-header-font-color: rgba(0, 0, 0, 0.5);

  &.first-header .header {
    justify-content: center !important;
  }
}

.vue3-easy-data-table__header th:first-child,
.vue3-easy-data-table__body td.can-expand {
  display: none;
}


.table-shops {
  .logo-img {
    margin: 15px 5px;
  }
  .table-footer {
    margin: 14px 0 10px 0;
  }
}



.header-shop-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.75);
  margin: 0;
}

.header-shop-link {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #20A0FF;
}

.button-go-shop {
  margin: 10px 20px  10px 0;
}

.vue3-easy-data-table__body .expand {
  background: rgba(228, 231, 237, 0.5)!important;
}

.static-sets-containers {
  position: relative;
}

.separator {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  margin: auto;
  top: 97px;
  background: rgb(161 174 183 / 73%);
}

</style>