<template>
  <div>
    <div class="add-find-filter flex-start-center">
      <AppButtonIcon
          :styleButton="'_red'"
          :icon="plus"
          @click="addShop('create')"
      >
        Добавить новый магазин
      </AppButtonIcon>
   

      <AppSearch class="find-shop"
          :placeholder="'Поиск'" 
          :value="search"
          @findModels="findModels"
      />
      <AppButtonIcon
          :styleButton="'_blue'"
          :icon="filter"
          :onlyIcon="true"
      />
      <h2 style="margin-left: 10px;">Мониторинг:</h2>
      <AppButtonIcon v-for="(item, i) in monitor"
              :key="i"
          style="margin-left:10px"
          :styleButton="(item.active)?'_green':'_red'"
          :placeholder="item.placeholder"
          :disabled="true"
      >
      {{ item.title }}
      </AppButtonIcon>
    </div>
    <!--
    <div class="add-find-filter flex-start-center">
      <AppButtonIcon
          :styleButton="'_blue'"
          :icon="calendar"
      >
        Выбрать период
      </AppButtonIcon>
    </div>
    -->
    <!--
    <div class="filter-container">
      <AppSelectorShow
          v-for="(filter, i) in filters"
          @openMenu="function(ev) {controlMenu(i, ev)}"
          :key="i"
          :options="filter"
          :open="statesFilters[i]"
      />
    </div>
    <div class="statistic-container">
      <h3 class="statistic-main-header">ИТОГО:</h3>
      <statisticSetGlobal
          v-for="(stats, i) of mainStats"
          :key="i"
          :items="stats"
      />
    </div>
    <h2 class="statistic-header">{{ 'Статистика за ' + month + ' ' + year }}</h2>
  -->
  <div style="display: flex;align-items:center;"> <span>Категории: </span>
      <AppButtonIcon
        
          style="margin:5px; font-size: 12px;"
          :styleButton="'_blue'"
          @click="categoryFilter(null)"
      >
        Все
      </AppButtonIcon>
      <AppButtonIcon
          v-for="category in categories " :key="category.id"
          style="margin:5px; font-size: 12px;"
          :styleButton="'_blue'"
          @click="categoryFilter(category.id)"
      >
      {{ category.title }}
      </AppButtonIcon>
    </div>
    Избранное
    <TableShops :shops="shopsFavourites" :meta="metashopsFavourites" @goPage="goPage" :hiddenPagin="true"/>
    <br>
    <TableShops :shops="shops" :meta="metaShops" @goPage="goPage" />
  </div>
</template>

<script>

import {plus, filter, calendar} from '@/assets/svg';
//import statisticSetGlobal from './listShops/statisticSetGlobal';
import TableShops from "./listShops/TableShops";
import api from "./api"


export default {
  name: "PageShops",
  components: {
    TableShops,
    //statisticSetGlobal
  },
  data() {
    return {
      search:"",
      categories: JSON.parse(localStorage.getItem("categories")) || [],
      monitor: [],
      page: 1,
      pageSize:10,
      category_id: null,
      shops: [],
      shopsFavourites: [],
      month: 'июнь',
      statesFilters: [],
      year: '2077',
      mainStats: [
        {
          "header": "Магазины/Визитки",
          "stats": [
            {
              "label": "Всего",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
            {
              "label": "Отключены",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
          ]
        },
        {
          "header": "Товары",
          "stats": [
            {
              "label": "Всего товаров",
              "add": 0,
              "notes": "Error!",
              "value": 2
            }
          ]
        },
        {
          "header": "Клиенты/посетители",
          "stats": [
            {
              "label": "Кленты",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
            {
              "label": "Доставка",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
            {
              "label": "Посетители",
              "add": 0,
              "notes": "Error!",
              "value": 0
            },
          ]
        },
      ],
    }
  },
  mounted() {
    this.getShops();
    this.getMonitor();
    this.getShopsFavourites();
  },
  methods:{
    findModels(search){
      this.search = search
      this.getShops();
    },
    categoryFilter(category_id){
      this.category_id = category_id
      this.getShops();
    },
    controlMenu(i, state) {
      this.statesFilters.forEach((item,i,arr) => arr[i] = false);
      this.statesFilters[i] = !state;
    },
    addShop(value){
      this.$router.push({ name: 'AddShop',  params: { id: value }})
    },
    goPage(page){
      this.page = page
      this.getShops()
    },
    async getMonitor(){
      await api.getMonitor().then((response) => {
       
              let data = response.data.data
              for (let index = 0; index < data.length; index++) {
              
                this.monitor.push({title: data[index]['title'], active:data[index]['active'], placeholder:data[index]['placeholder']});
              }
              })
              /*
              .catch((error) => {
                
                  if (error.response.data.message)
                    this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                    
              })
              */
        
    },
    async getShopsFavourites(){
      let data = {page: this.page, pageSize: this.pageSize, favorites:true}
      await api.getShops(data).then((response) => {
              this.shopsFavourites = response.data.data
              this.metashopsFavourites = response.data.meta
              console.log(this.shops,"this.shops")
              /*
              if (response.data.message){
                this.showMessageNotice('', response.data.message,'success')
              }
              */
              })
              /*
              .catch((error) => {
                  if (error.response.data.message)
                    this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
              })
              */
        
    },
    async getShops(){
      let data = {page: this.page, pageSize: this.pageSize, category_id: this.category_id, search:this.search}
      await api.getShops(data).then((response) => {
              this.shops = response.data.data
              this.metaShops = response.data.meta
              console.log(this.shops,"this.shops")
              /*
              if (response.data.message){
                this.showMessageNotice('', response.data.message,'success')
              }
              */
              })
              /*
              .catch((error) => {
                  if (error.response.data.message)
                    this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
              })
              */
        
    },
  },
  setup() {
    return {plus, filter, calendar}
  }

}
</script>

<style lang="scss" scoped>

.find-shop {
  width: 420px;
  margin: 0 20px;
}

.add-find-filter {
  margin-bottom: 16px;
}

</style>