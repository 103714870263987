<template>
  <div v-if="data">
    <div class="message-notice__margin-container" :class="data.showNow ? 'show-message-notice' : ''">
      <div class="message-notice__container" :style="type">
        <div class="message-notice__text-container">
        <!-- <h3 class="message-notice__header">{{header}}</h3> -->
          <div class="message-notice__value" v-html="data.currentMessage"/>
        </div>
        <!--
        <AppButton :styleButton ="'_red'" class="message-notice__button">
          Отправить
        </AppButton>
        -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppMessageNotice",
  components: {},
  props: ['data'],
  computed:{
    type(){
      if(this.data.currentStatus=="success") return "background-color: aquamarine;"
      return 'background-color: rgb(231, 183, 182);'
    }
}
}



</script>

<style scoped>

.success {
  
}
.error {
  
}

.message-notice__margin-container {
  z-index: 999;
  margin: auto;
  position: fixed;
  top: -110%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .9s;
  opacity: 0;
}
.message-notice__container {
  background: #FFFFFF;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: start;
  margin: 20px;
  justify-content: space-around;
  width: 607px;
}

.message-notice__value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136.4%;
  color: rgba(0, 0, 0, 0.5);
}

.message-notice__header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 101.9%;
  color: #000000;
  margin-bottom: 10px;
}

.message-notice__button {
  max-width: 110px;
  margin-left: 10px;
}

.show-message-notice {
  top: 10px;
  transition: .9s;
  opacity: 1;
}


</style>