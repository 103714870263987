import axios from 'axios';
import { BACKEND_URL_LOGIN } from '@/store'

export default {
    actions: {
        async getToken(ctx, newData) {
            axios.post(BACKEND_URL_LOGIN, newData)
            .then(res => {
            
                localStorage.setItem('token', res.data.token);
                ctx.commit('setLoginData', res.data.token);
                window.location.href = '/'
            })
            .catch(() => {
                localStorage.setItem('token', "");
            })
        }
    },
    mutations: {
        setLoginData(state, newData) {
            state.data = newData;
        },
    },
    state: {
        data: {}
    }
}
