import axios from 'axios'

import { API_LOCATION } from "@/api"
const url = API_LOCATION+"/shops"
const monitor = API_LOCATION+"/monitor"


export default {
    getShops(data) {
        return axios.get(`${url}`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data
        })
    },
    getPeopleChart(shop_id, data) {
        return axios.get(`${url}/${shop_id}/peoples-chart`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data
        })
    },
  
    deleteItem(id) {

        return axios.delete(`${url}/${id}`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }) 
    },
    getMonitor() {
        return axios.get(`${monitor}`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }) 
    },
    createModel(data) {
        return axios.post(`${url}`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
   
}