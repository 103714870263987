import { createApp } from 'vue';

import componentsUI from '@/components/UI';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import { SetupCalendar, DatePicker } from 'v-calendar';


// Register components in your 'main.js'

const app = createApp(App);

import '@/style/style.scss';


app.use(SetupCalendar, {})
//app.component('Calendar', Calendar)
app.component('DatePicker', DatePicker)
app.component('EasyDataTable', Vue3EasyDataTable);
componentsUI.forEach(component => {
    app.component(component.name, component);
});

app
    .use(router)
    .use(store)
    .mount('#app')
